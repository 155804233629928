/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;900&display=swap');
@import "../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/font-awesome/scss/font-awesome";
@import "../../node_modules/line-awesome/dist/line-awesome/css/line-awesome.css";
@import "../../node_modules/animate.css/animate";
@import '../../node_modules/font-awesome/scss/font-awesome';
@import "../../node_modules/eva-icons/style/eva-icons.css";
@import '../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';
@import '~flatpickr/dist/flatpickr.css';

@import "mixins";
@import "auth";
@import "overrides";
@import "general";
@import "utils";
